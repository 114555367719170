import { LocalStorageEntry, defaultEntries } from "Types/LocalStorage";

export namespace LocalStorage {
  export function InitLocalStorage() {
    if (window.localStorage === undefined) {
      console.error("Unable to initialize LocalStorage because it is unsupported by this browser.")
      return false;
    }

    const map = Object.entries(defaultEntries);

    for (let i = 0; i < map.length; i++) {
      const entry = map[i];
      const key = entry[0];
      const value = entry[1];

      if (localStorage.getItem(key) != null) continue;

      localStorage.setItem(key, String(value));
    }

    console.log("LocalStorage Initialized");

    return true;
  }

  export function GetItem(key: keyof typeof defaultEntries): string {
    if (window.localStorage === undefined) {
      console.error("Unable to retrieve item from LocalStorage because it is unsupported by this browser.")
      return "";
    }

    let value = window.localStorage.getItem(key);

    if (value == null) value = String(defaultEntries[key]);

    if (window.dispatchEvent !== undefined) {
      window.dispatchEvent(new CustomEvent("LocalStorageChanged", { detail: { type: "Get", key, value } as LocalStorageEntry }));
    }
    else {
      console.warn("Unable to dispatch CustomEvent for event type 'LocalStorageChanged' because custom events are unsupported by this browser.");
    }

    return value;
  }

  export function SetItem(key: keyof typeof defaultEntries, value: any): boolean {
    if (window.localStorage === undefined) {
      console.error("Unable to set item in LocalStorage because it is unsupported by this browser.")
      return false;
    }

    window.localStorage.setItem(key, String(value));

    if (window.dispatchEvent !== undefined) {
      window.dispatchEvent(new CustomEvent("LocalStorageChanged", { detail: { type: "Set", key, value } as LocalStorageEntry }));
    }
    else {
      console.warn("Unable to dispatch CustomEvent for event type 'LocalStorageChanged' because custom events are unsupported by this browser.");
    }

    return true;
  }

  export function DeleteItem(key: keyof typeof defaultEntries): boolean {
    if (window.localStorage === undefined) {
      console.error("Unable to delete item from LocalStorage because it is unsupported by this browser.")
      return false;
    }

    window.localStorage.removeItem(key);

    if (window.dispatchEvent !== undefined) {
      window.dispatchEvent(new CustomEvent("LocalStorageChanged", { detail: { type: "Delete", key, value: undefined } as LocalStorageEntry }));
    }
    else {
      console.warn("Unable to dispatch CustomEvent for event type 'LocalStorageChanged' because custom events are unsupported by this browser.");
    }

    return true;
  }

  export function ClearLocalStorage() {
    if (window.localStorage === undefined) {
      console.error("Unable to clear LocalStorage because it is unsupported by this browser.")
      return false;
    }

    window.localStorage.clear();

    if (window.dispatchEvent !== undefined) {
      window.dispatchEvent(new CustomEvent("LocalStorageChanged", { detail: { type: "Clear", key: undefined, value: undefined } as LocalStorageEntry }));
    }
    else {
      console.warn("Unable to dispatch CustomEvent for event type 'LocalStorageChanged' because custom events are unsupported by this browser.");
    }

    return true;
  }
}
