// ARIA
import ARIACommon_EN_SS from "./ARIA/Common.json"

// Common
import Common_EN_SS from "./Common.json";
import Languages from "../Languages.json";

// Components
import FileTable_EN_SS from "./Components/FileTable.json";
import FileTableEntry_EN_SS from "./Components/FileTableEntry.json";
import ScreenRecorderStatus_EN_SS from "./Components/ScreenRecorderStatus.json";

// Pages
import About_EN_SS from "./Pages/About.json";
import Error_EN_SS from "./Pages/Error.json";
import FileExplorer_EN_SS from "./Pages/FileExplorer.json";
import Home_EN_SS from "./Pages/Home.json";
import ModelViewer_EN_SS from "./Pages/ModelViewer.json";
import ScreenRecorder_EN_SS from "./Pages/ScreenRecorder.json";
import Settings_EN_SS from "./Pages/Settings.json";
import Tools_EN_SS from "./Pages/Tools.json";
import WordGuesser_EN_SS from "./Pages/WordGuesser.json";

export const Localizations_EN_SS = {
  // ARIA
  ARIACommon: ARIACommon_EN_SS,

  // Common
  Common: Common_EN_SS,
  Language: Languages,

  // Components
  FileTable: FileTable_EN_SS,
  FileTableEntry: FileTableEntry_EN_SS,
  ScreenRecorderStatus: ScreenRecorderStatus_EN_SS,

  // Pages
  About: About_EN_SS,
  Error: Error_EN_SS,
  FileExplorer: FileExplorer_EN_SS,
  Home: Home_EN_SS,
  ModelViewer: ModelViewer_EN_SS,
  ScreenRecorder: ScreenRecorder_EN_SS,
  Settings: Settings_EN_SS,
  Tools: Tools_EN_SS,
  WordGuesser: WordGuesser_EN_SS
};
