// Globals
import { IconButton, TableCell, TableRow } from "@mui/material";
import {
  ArrowUpward as UpIcon,
  Article as DocumentIcon,
  AudioFile as AudioFileIcon,
  Download as DownloadIcon,
  Folder as FolderIcon,
  Photo as ImageIcon,
  QuestionMark as QuestionMarkIcon,
  RestartAlt as RootIcon,
  VideoFile as VideoFileIcon,
  ViewInAr as ModelIcon
} from "@mui/icons-material";
import filesize from "filesize";

// Types
import type { FileProperties } from "Types/Files";
import type { ComponentBase } from "Types/Components";
import { FileType } from "Lib/MimeTypeParser";

export interface FileTableColumnOptions {
  icon?: boolean;
  filename?: boolean;
  extension?: boolean;
  fileSize?: boolean;
  dateCreated?: boolean;
  dateModified?: boolean;
  download?: boolean;
}

interface FileTableEntryProps extends ComponentBase {
  columns?: FileTableColumnOptions;
  autoProcess?: boolean;
  file?: FileProperties;
  selected?: boolean;
  onFileOpen?: (file: FileProperties) => void;
  onFileDownload?: (file: FileProperties) => void;
}

export default function FileTableEntry(props: FileTableEntryProps) {
  let columns = props.columns;
  let file: FileProperties = props.file as unknown as FileProperties;

  if (file == null) {
    file = {
      basename: "",
      dateCreated: 0,
      dateModified: 0,
      extension: "",
      fileSize: 0,
      filename: "",
      isDir: false,
      path: "",
      extra: {
        mimeType: FileType.Unknown,
        canBeOpened: false,
        url: "",
      },
    };
  }

  if (columns == null) {
    columns = {
      icon: true,
      filename: true,
      extension: true,
      fileSize: true,
      dateCreated: true,
      dateModified: true,
      download: true,
    };
  }

  if (props.autoProcess) {
    if (file.isDir) {
      columns = {
        icon: true,
        filename: true,
        extension: true,
        fileSize: false,
        dateCreated: true,
        dateModified: true,
        download: false,
      };
    } else {
      if (file.dateCreated == null) columns.dateCreated = false;
      if (file.dateModified == null) columns.dateModified = false;
      if (file.fileSize == null) columns.fileSize = false;
    }
  }

  const onFileOpen = () => {
    if (props.onFileOpen) props.onFileOpen(file);
  };

  const fileIcon = () => {
    switch (file.extra?.specialEntryType) {
      case "Back":
        return <UpIcon />;
      case "Root":
        return <RootIcon />;
    }

    if (file.isDir) return <FolderIcon />;

    switch (file.extra?.mimeType) {
      case FileType.Audio:
        return <AudioFileIcon />;
      case FileType.Document:
        return <DocumentIcon />;
      case FileType.Image:
        return <ImageIcon />;
      case FileType.Video:
        return <VideoFileIcon />;
      case FileType.Model:
        return <ModelIcon />;
      case FileType.Unknown:
      default:
        return <QuestionMarkIcon />;
    }
  };

  return (
    <TableRow
      hover
      selected={props.selected}
      onClick={onFileOpen}
      style={{ cursor: "pointer" }}
    >
      <TableCell>{columns.icon ? fileIcon() : null}</TableCell>
      <TableCell>{columns.filename ? file.filename : null}</TableCell>
      <TableCell>{columns.extension ? file.extension : null}</TableCell>
      <TableCell>{columns.fileSize ? filesize(file.fileSize) : null}</TableCell>
      <TableCell>
        {columns.dateCreated
          ? (() => {
              const date = new Date(0);
              date.setUTCSeconds(file.dateCreated);
              return date.toISOString();
            })()
          : null}
      </TableCell>
      <TableCell>
        {columns.dateModified
          ? (() => {
              const date = new Date(0);
              date.setUTCSeconds(file.dateModified);
              return date.toISOString();
            })()
          : null}
      </TableCell>
      <TableCell>
        {columns.download ? (
          <IconButton
            href={
              file.extra != null && file.extra.url != null ? file.extra.url : ""
            }
            onClick={() => {
              if (props.onFileDownload) props.onFileDownload(file);
            }}
            disabled={file.isDir}
            download
          >
            <DownloadIcon />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
