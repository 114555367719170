// Globals
import React, { RefObject, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Components
import StatusBanner, { StatusType } from "Components/StatusBanner/StatusBanner";
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";

interface ScreenRecorderPageProps extends Page {}

interface CustomDisplayMediaStreamConstraints extends MediaTrackConstraints {
  cursor: string;
}

interface CustomMediaStreamConstraints extends MediaStreamConstraints {
  video: CustomDisplayMediaStreamConstraints;
}

function isScreenRecordingSupported() {
  if (
    navigator != null &&
    "mediaDevices" in navigator &&
    "getDisplayMedia" in navigator.mediaDevices
  )
    return true;
  return false;
}

const mediaStreamOptions: CustomMediaStreamConstraints = {
  video: {
    frameRate: 60,
    cursor: "always",
  },
  audio: true,
};

const mediaRecorderOptions: MediaRecorderOptions = {
  mimeType: "video/webm",
  audioBitsPerSecond: 128000,
};

const mediaTrackOptions: MediaTrackConstraints = {};

function ScreenRecorderPage({ changeTitleCallback }: ScreenRecorderPageProps) {
  const t = useTranslation(["ScreenRecorder", "ScreenRecorderStatus"]).t;
  useEffect(() => {
    changeTitleCallback(t("ScreenRecorder:pageTitle"));
  }, [changeTitleCallback, t]);

  const [status, setStatus] = useState({
    title: t("ScreenRecorderStatus:statusReady"),
    message: t("ScreenRecorderStatus:statusReadyDescription"),
    type: StatusType.info,
  });
  const [stream, setStream] = useState(undefined as unknown as MediaStream);
  const [recorder, setRecorder] = useState(
    undefined as unknown as MediaRecorder
  );
  const [downloadURL, setDownloadURL] = useState("");
  const [prevDownloadURL, setPrevDownloadURL] = useState("");
  const [recordBuffer, setRecordBuffer] = useState([] as Array<Blob>);

  const videoRef: RefObject<HTMLVideoElement> = React.createRef();

  const isDownloadable = () => {
    return downloadURL.length > 0;
  };

  const download = () => {
    if (recordBuffer.length > 0) {
      const blob = new Blob(recordBuffer, { type: "video/mp4" });

      if (blob.size > 0) {
        const url = URL.createObjectURL(blob);

        setPrevDownloadURL(downloadURL);
        setDownloadURL(url);
      } else {
        console.error("Unable to create blob for download.");
      }
    }
  };

  const resetState = () => {
    URL.revokeObjectURL(prevDownloadURL);
    setRecordBuffer([]);
    setStream(undefined as unknown as MediaStream);
    setRecorder(undefined as unknown as MediaRecorder);
    setStatus({
      title: t("ScreenRecorderStatus:statusReady"),
      message: t("ScreenRecorderStatus:statusReadyDescription"),
      type: StatusType.info,
    });
  };

  const startRecording = async () => {
    stopRecording();
    navigator.mediaDevices
      .getDisplayMedia(mediaStreamOptions)
      .then((stream) => {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.applyConstraints(mediaTrackOptions);
          track.onended = () => stopRecording();
        });
        setStream(stream);
        setRecorder(new MediaRecorder(stream, mediaRecorderOptions));
        setStatus({
          title: t("ScreenRecorderStatus:statusRecordingInProgress"),
          message: t(
            "ScreenRecorderStatus:statusRecordingInProgressDescription"
          ),
          type: StatusType.success,
        });
      })
      .catch((error: DOMException) => {
        if (error.name === "NotAllowedError") {
          setStatus({
            title: t("ScreenRecorderStatus:statusError"),
            message: t(
              "ScreenRecorderStatus:statusErrorPermissionDeniedDescription"
            ),
            type: StatusType.error,
          });
        } else {
          setStatus({
            title: t("ScreenRecorderStatus:statusError"),
            message: error.message,
            type: StatusType.error,
          });
        }
      });
  };

  const stopRecording = async () => {
    if (stream != null) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (recorder != null) {
      recorder.stop();
    }

    download();
    resetState();
  };

  useEffect(() => {
    const writeToBuffer = (event: BlobEvent) => {
      if (event.data.size > 0) {
        recordBuffer.push(event.data);
      } else {
        console.warn(
          "Received request to write data to buffer but data was empty"
        );
      }
    };

    if (videoRef != null && videoRef.current != null) {
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => {
        if (videoRef != null && videoRef.current != null)
          videoRef.current.play();
      };
    }

    if (!isScreenRecordingSupported()) {
      setStatus({
        title: t("ScreenRecorderStatus:statusUnsupported"),
        message: t("ScreenRecorderStatus:statusUnsupportedDescription"),
        type: StatusType.error,
      });
    }

    if (recorder != null && recorder.state !== "recording") {
      recorder.ondataavailable = writeToBuffer;
      recorder.start(50);
    }
  }, [stream, videoRef, recorder, recordBuffer, t]);

  return (
    <PageContainer split>
      <div className="ScreenRecorderLeft">
        <Typography variant="h4">{t("ScreenRecorder:pageTitle")}</Typography>
        <Typography variant="body1">
          {t("ScreenRecorder:screenRecorderDescription")}
        </Typography>
        <div className="ScreenRecorderNotes">
          <Typography variant="h5">
            {t("ScreenRecorder:screenRecorderNotesHeader")}
          </Typography>
          <ul className="ScreenRecorderNotesInner">
            {(
              // @ts-ignore
              t("ScreenRecorder:screenRecorderNotes", {
                returnObjects: true,
              }) as Array<string>
            ).map((note, index) => (
              <li key={`note_${index}`}>{note}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="ScreenRecorderRight">
        <StatusBanner
          title={status.title}
          message={status.message}
          type={status.type}
        />
        <div className="ScreenRecorderButtonContainer">
          <Button
            className="ScreenRecorderButton"
            disabled={!isScreenRecordingSupported()}
            onClick={startRecording}
            variant="outlined"
            color="success"
          >
            {t("ScreenRecorder:screenRecorderStartButton")}
          </Button>
          <Button
            className="ScreenRecorderButton"
            disabled={!isScreenRecordingSupported()}
            onClick={stopRecording}
            variant="outlined"
            color="error"
          >
            {t("ScreenRecorder:screenRecorderStopButton")}
          </Button>
          <Button
            className="ScreenRecorderButton"
            disabled={!isDownloadable()}
            href={downloadURL}
            download
            variant="outlined"
            color="primary"
          >
            {t("ScreenRecorder:screenRecorderDownloadButton")}
          </Button>
        </div>
        <div className="ScreenRecorderPreviewContainer">
          {stream != null ? (
            <Typography variant="caption">
              {t("ScreenRecorder:screenRecorderPreviewHeader")}
            </Typography>
          ) : null}
          <video ref={videoRef} className="ScreenRecorderPreviewEmbed" />
        </div>
      </div>
    </PageContainer>
  );
}

export default ScreenRecorderPage;
