// Globals
import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";
import type { WordList } from "Types/Misc";

interface WordGuesserPageProps extends Page {

}

function WordGuesserPage({ changeTitleCallback }: WordGuesserPageProps) {
  const t = useTranslation(["WordGuesser"]).t;

  useEffect(() => {
    changeTitleCallback(t("WordGuesser:pageTitle"));
  }, [changeTitleCallback, t]);

  let wordList: WordList = null as unknown as WordList;

  const [word, setSearchTerm] = useState("");
  const [optionIsWordle, setOptionIsWordle] = useState(false);

  fetch("https://cdn.yepdevelopment.com/public/wordlists/en_wordlist.json").then((response) => {
    if (response.ok) {
      response.json().then((wordlist: WordList) => {
        wordList = wordlist;
      });
    }
  });

  function handleSearchTermChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  function handleOptionIsWordleToggled(event: ChangeEvent<HTMLInputElement>) {
    setOptionIsWordle(event.target.checked);
  }

  useEffect(() => {
    if (wordList != null) {

    }
  }, [word, wordList]);

  return (
    <PageContainer split>
      <div className="WordGuesserDescriptionSection">
        <Typography variant="h4">{t("WordGuesser:pageTitle")}</Typography>
        <Typography variant="body1">{t("WordGuesser:pageDescription")}</Typography>
        <div className="WordGuesserInstructionsSection">
          <Typography variant="h6">{t("WordGuesser:instructionsTitle")}</Typography>
          <ul className="WordGuesserInstructionsList">
            {
              // @ts-ignore
              (t("WordGuesser:usageInstructions", { returnObjects: true } ) as Array<string>).map((instruction, index) => {
                return <li key={`Instruction_${index}`}>{instruction}</li>
              })
            }
          </ul>
        </div>
      </div>
      <div className="WordGuesserInterfaceSection">
        <div className="WordGuesserInterfaceInputSection">
          <TextField className="WordGuesserInputField" label={t("WordGuesser:searchTermInputFieldLabel")} placeholder={t("WordGuesser:searchTermInputFieldPlaceholder")} value={word} onChange={handleSearchTermChange}></TextField>
          <div className="WordGuesserInterfaceOptionsContainer">
            <Typography variant="h6" sx={{ textAlign: "left" }}>{t("WordGuesser:searchOptionsTitle")}</Typography>
            <FormGroup>
              <FormControlLabel label={t("WordGuesser:searchOptionsIsWordle")} control={<Checkbox checked={optionIsWordle} onChange={handleOptionIsWordleToggled} />} />
            </FormGroup>
          </div>
        </div>
        <Typography variant="body1">{word}</Typography>
        <div className="WordList">

        </div>
      </div>
    </PageContainer>
  );
}

export default WordGuesserPage;
