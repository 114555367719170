// Globals
import React, { useEffect, useState } from "react";
import {
  AppBar,
  CssBaseline,
  IconButton,
  List,
  SwipeableDrawer,
  Toolbar,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  Abc as WordIcon,
  Construction as ToolIcon,
  FolderOutlined as FolderIcon,
  HomeOutlined as HomeIcon,
  InfoOutlined as AboutIcon,
  Menu as MenuIcon,
  RadioButtonChecked as RecordIcon,
  SettingsOutlined as SettingsIcon,
  ViewInAr as ModelIcon,
} from "@mui/icons-material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

// Lib
import { LocalStorage } from "Lib/LocalStorage";

// Pages
import HomePage from "Pages/Home/Home";
import FileExplorerPage from "Pages/FileExplorer/FileExplorer";
import ModelViewerPage from "Pages/ModelViewer/ModelViewer";
import WordGuesserPage from "Pages/WordGuesser/WordGuesser";
import ToolsPage from "Pages/Tools/Tools";
import ScreenRecorderPage from "Pages/ScreenRecorder/ScreenRecorder";
import AboutPage from "Pages/About/About";
import SettingsPage from "Pages/Settings/Settings";
import ErrorPage from "Pages/Error/Error";

// Components
import HybridListItem from "Components/Lists/HybridListItem/HybridListItem";

// Types
import { LocalStorageEntry } from "Types/LocalStorage";
import { Languages } from "Types/Locale";

// Assets
import "./App.css";
import { DarkTheme, LightTheme } from "theme";
import { Localizations, defaultNS } from "Localization/Localizations";

i18n.use(initReactI18next).init({
  resources: Localizations,
  defaultNS: defaultNS,
  lng: LocalStorage.GetItem("language"),
  fallbackLng: Languages.AmericanEnglish,
  returnNull: false,
});

function App() {
  // @ts-ignore
  const t = useTranslation([
    "ARIACommon",
    "About",
    "Common",
    "Error",
    "FileExplorer",
    "FileTable",
    "Home",
    "ModelViewer",
    "ScreenRecorder",
    "Settings",
    "Tools",
    "WordGuesser",
  ]).t;

  const [theme, setTheme] = useState(
    LocalStorage.GetItem("darkMode").toLowerCase() === "true" ? DarkTheme : LightTheme
  );

  const localStorageEventHandler = (event: CustomEvent<LocalStorageEntry>) => {
    const eventData = event.detail;

    if (eventData === undefined) {
      console.warn("LocalStorageChanged eventData was undefined")
      return;
    }

    if (eventData.type === "Set") {
      switch (eventData.key) {
        case "darkMode":
          setTheme(Boolean(eventData.value) === true ? DarkTheme : LightTheme);
          break;
        case "language":
          i18n.changeLanguage(eventData.value);
          break;
      }
    }

  };

  useEffect(() => {
    window.addEventListener("LocalStorageChanged", localStorageEventHandler);
    return () => window.removeEventListener("LocalStorageChanged", localStorageEventHandler);
  });

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const toggleDrawer = (state: boolean) => {
    if (state != null) {
      setOpen(state);
    } else {
      setOpen(!open);
    }
  };

  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppBar enableColorOnDark>
            <Toolbar sx={{ background: "primary.main" }}>
              <IconButton
                aria-label={t("ARIACommon:navigationDrawerButton")}
                color="inherit"
                onClick={() => toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Toolbar /> { /* This is here so that the page doesn't go under the AppBar */}
          <SwipeableDrawer
            open={open}
            anchor="left"
            onOpen={() => toggleDrawer(true)}
            onClose={() => toggleDrawer(false)}
          >
            <List className="NavigationList NavigationListTop">
              <HybridListItem
                icon={<HomeIcon />}
                text={t("Home:pageTitle")}
                href="/home"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                icon={<FolderIcon />}
                text={t("FileExplorer:pageTitle")}
                href="/fileexplorer"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                icon={<ModelIcon />}
                text={t("ModelViewer:pageTitle")}
                href="/modelviewer"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                disabled
                icon={<ToolIcon />}
                text={t("Tools:pageTitle")}
                href="/tools"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                disabled
                icon={<WordIcon />}
                text={t("WordGuesser:pageTitle")}
                href="/wordguesser"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                icon={<RecordIcon />}
                text={t("ScreenRecorder:pageTitle")}
                href="/screenrecorder"
                onClick={() => toggleDrawer(false)}
              />
            </List>
            <List className="NavigationList NavigationListBottom">
              <HybridListItem
                icon={<AboutIcon />}
                text={t("About:pageTitle")}
                href="/about"
                onClick={() => toggleDrawer(false)}
              />
              <HybridListItem
                icon={<SettingsIcon />}
                text={t("Settings:pageTitle")}
                href="/settings"
                onClick={() => toggleDrawer(false)}
              />
            </List>
          </SwipeableDrawer>
          <Routes>
            <Route
              path="*"
              element={
                <ErrorPage changeTitleCallback={setTitle} errorType={404} />
              }
            />
            <Route
              path="403"
              element={
                <ErrorPage changeTitleCallback={setTitle} errorType={403} />
              }
            />
            <Route
              path="/"
              element={<HomePage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/home"
              element={<HomePage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/fileexplorer"
              element={<FileExplorerPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/modelviewer"
              element={<ModelViewerPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/tools"
              element={<ToolsPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/wordguesser"
              element={<WordGuesserPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/screenrecorder"
              element={<ScreenRecorderPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/about"
              element={<AboutPage changeTitleCallback={setTitle} />}
            />
            <Route
              path="/settings"
              element={<SettingsPage changeTitleCallback={setTitle} />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
