import {
  AppBar,
  Card,
  Grow,
  IconButton,
  LinearProgress,
  Modal,
  Toolbar,
  Typography
} from "@mui/material";
import { Close as CloseIcon} from "@mui/icons-material";

interface FileViewerProps {
  open: boolean,
  children: JSX.Element | JSX.Element[] | undefined,
  title?: string,
  keepMounted?: boolean,
  isLoading?: boolean,
  onClose?: () => void
}

export default function FileViewer({ open, children, title, keepMounted, isLoading, onClose }: FileViewerProps) {
  return (
    <Modal
      className="FileViewerModal"
      open={open}
      closeAfterTransition
      keepMounted={keepMounted}
    >
      <Grow in={open}>
        <div className="FileViewerContainer">
          <AppBar position="relative" enableColorOnDark>
            <Toolbar className="FileViewerToolbar">
              {title != null && title.length > 0 ? <Typography variant="h5" sx={{ marginBottom: "5px" }}>{title}</Typography> : null}
              <IconButton
                onClick={() => { if (onClose) onClose() }}
                sx={{ marginLeft: "auto", color: "white" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            {isLoading != null && isLoading ? <LinearProgress variant="indeterminate" /> : null}
          </AppBar>
          <Card className="FileViewerContentContainer">
            {children}
          </Card>
        </div>
      </Grow>
    </Modal>
  );
}
