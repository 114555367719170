// Globals
import {
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Lib
import { LocalStorage } from "Lib/LocalStorage";

// Components
import Section from "Components/Containers/Section/Section";
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";
import { Languages } from "Types/Locale";

interface SettingsPageProps extends Page {}

function SettingsPage({ changeTitleCallback }: SettingsPageProps) {
  // @ts-ignore
  const t = useTranslation(["Common", "Language", "Settings"]).t;
  const [darkModeState, setDarkModeState] = useState(
    LocalStorage.GetItem("darkMode").toLowerCase() === "true"
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    LocalStorage.GetItem("language").toLowerCase()
  );

  useEffect(() => {
    changeTitleCallback(t("Settings:pageTitle"));
  }, [changeTitleCallback, t]);

  const onDarkModeToggle = (event: SyntheticEvent, checked: boolean) => {
    LocalStorage.SetItem("darkMode", checked);
    setDarkModeState(checked);
  };

  const onLanguageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLanguage = event.target.value;
    if (newLanguage == null) return;
    LocalStorage.SetItem("language", newLanguage);
    setSelectedLanguage(newLanguage);
  };

  const generateLanguageMenuItems = () => {
    const languages = Object.entries(Languages);
    const menuItems: JSX.Element[] = [];

    for (let i = 0; i < languages.length; i++) {
      const language = languages[i];
      menuItems.push(
        <MenuItem key={language[0]} value={language[1]}>
          {t(`Language:${language[1]}`)}
        </MenuItem>
      );
    }

    return menuItems;
  };

  generateLanguageMenuItems();

  return (
    <PageContainer>
      <Section title={t("Settings:sectionGeneral")}>
        <TextField
          select
          label={t("Settings:labelLanguage")}
          value={selectedLanguage}
          onChange={onLanguageChange}
        >
          {generateLanguageMenuItems()}
        </TextField>
      </Section>
      <Section title={t("Settings:sectionAppearance")}>
        <FormControlLabel
          control={<Switch />}
          label={t("Settings:switchDarkMode")}
          checked={darkModeState}
          onChange={onDarkModeToggle}
        />
      </Section>
    </PageContainer>
  );
}

export default SettingsPage;
