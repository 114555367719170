// Globals
import { Toolbar, useTheme } from "@mui/material";

// Hooks
import useClassNames from "Hooks/useClassNames";
import useStyles from "Hooks/useStyles";

// Types
import type { ComponentBase } from "Types/Components";
import type { ReactNode } from "react";

export interface PageContainerProps extends ComponentBase {
  adaptive?: boolean,
  children: ReactNode
  noPadding?: boolean,
  split?: boolean,
}

function PageContainer(props: PageContainerProps) {
  const theme = useTheme();
  const style = useStyles({ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }, props.style);
  let classNames = useClassNames("PageContainer", props.className);

  if (props.adaptive === null) {
    props.adaptive = true;
  }

  if (!props.adaptive) {
    classNames = classNames.concat(" ", "PageContainerNonAdaptive");
  }

  if (props.noPadding) {
    classNames = classNames.concat(" ", "NoPadding");
  }

  if (props.split) {
    classNames = classNames.concat(" ", "PageContainerSplit");
  }

  return (
    <div className={classNames} style={style}>
      {props.children}
    </div>
  )
}

export default PageContainer;
