// Globals
import React, { useCallback, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

// Hooks
import useClassNames from "Hooks/useClassNames";

// Components
import FileTableEntry from "Components/Tables/FileTableEntry/FileTableEntry";

// Types
import type { FileProperties } from "Types/Files";
import { useTranslation } from "react-i18next";
import { FileType } from "Lib/MimeTypeParser";

interface FileTableProps {
  className?: string;
  error?: boolean;
  title?: string;
  errorMessage?: string;
  fileList?: FileProperties[];
  onFileDownload?: (file: FileProperties) => void;
  onFileOpen?: (file: FileProperties) => void;
  onReload?: () => void;
  onBack?: () => void;
  onRoot?: () => void;
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  searchQuery?: string;
  stickyHeader?: boolean;
}

export default function FileTable(props: FileTableProps) {
  const classNames = useClassNames("FileTableOuterContainer", props.className);
  // @ts-ignore
  const t = useTranslation(["FileTable", "FileTableEntry"]).t;
  let errorMessage = props.errorMessage;

  if (errorMessage == null) {
    errorMessage = t("FileTable:errorMessageGeneric");
  }

  const FilterList = useCallback(() => {
    if (props.fileList == null) return null;

    const filteredFileList: FileProperties[] = [];

    if (props.searchQuery != null && props.searchQuery.length > 0) {
      const filteredSearchString = props.searchQuery
        .trim()
        .toLowerCase()
        .replaceAll(" ", "|");
      const searchTermRegEx = new RegExp(`(${filteredSearchString})`, "i");

      for (const file of props.fileList) {
        if (searchTermRegEx.test(file.basename.toLowerCase()))
          filteredFileList.push(file);
      }

      return filteredFileList;
    }

    return null;
  }, [props.fileList, props.searchQuery]);

  const files = (() => {
    const fileList =
      props.searchQuery != null && props.searchQuery.length > 0
        ? FilterList()
        : props.fileList;

    if (fileList == null) return null;

    return [
      <FileTableEntry
        key="fileTableStaticEntryRoot"
        columns={{
          icon: true,
          filename: true,
          extension: false,
          fileSize: false,
          dateCreated: false,
          dateModified: false,
          download: false,
        }}
        file={{
          basename: "",
          dateCreated: 0,
          dateModified: 0,
          extension: "",
          fileSize: 0,
          filename: t("FileTableEntry:fileTableStaticEntryRoot"),
          isDir: true,
          extra: {
            mimeType: FileType.Unknown,
            canBeOpened: false,
            url: "/",
            specialEntryType: "Root"
          },
        }}
        onFileOpen={props.onRoot}
      />,
      <FileTableEntry
        key="fileTableStaticEntryParent"
        columns={{
          icon: true,
          filename: true,
          extension: false,
          fileSize: false,
          dateCreated: false,
          dateModified: false,
          download: false,
        }}
        file={{
          basename: "",
          dateCreated: 0,
          dateModified: 0,
          extension: "",
          fileSize: 0,
          filename: t("FileTableEntry:fileTableStaticEntryParent"),
          isDir: true,
          extra: {
            mimeType: FileType.Unknown,
            canBeOpened: false,
            url: "../",
            specialEntryType: "Back"
          },
        }}
        onFileOpen={props.onBack}
      />,
      ...fileList.map((file, index) => {
        return (
          <FileTableEntry
            autoProcess
            key={`file_${file.basename}_${file.dateModified}`}
            file={file}
            onFileOpen={props.onFileOpen}
            onFileDownload={props.onFileDownload}
          />
        );
      }),
    ];
  })();

  return (
    <div className={classNames}>
      <Card className="FileTableTop">
        <div className="FileTableTopLeft">
          {props.onSearchChange != null ? (
            <TextField
              className="FileTableSearchBox"
              label={t("FileTable:fileTableSearchLabel")}
              placeholder={t("FileTable:fileTableSearchPlaceholder")}
              value={props.searchQuery}
              onChange={props.onSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {props.onSearchClear != null &&
                    props.searchQuery != null &&
                    props.searchQuery.length > 0 ? (
                      <IconButton
                        onClick={props.onSearchClear}
                        disabled={
                          !(
                            props.searchQuery != null &&
                            props.searchQuery.length > 0
                          )
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </div>
        <div className="FileTableTopCenter">
          {props.title != null ? <Typography variant="h4">{props.title}</Typography> : null }
        </div>
        {props.onReload != null ? (
          <Button
            className="FileTableReloadButton"
            onClick={() => {
              if (props.onReload) props.onReload();
            }}
          >
            {t("FileTable:fileTableReloadButton")}
          </Button>
        ) : null}
      </Card>
      <TableContainer
        className={`FileTableContainer ${
          props.stickyHeader ? "InsetScroll" : ""
        }`}
        component={Paper}
      >
        <Table className="FileTable" stickyHeader={props.stickyHeader}>
          <TableHead>
            <TableRow>
              <TableCell>{t("FileTable:fileTableIcon")}</TableCell>
              <TableCell>{t("FileTable:fileTableFilename")}</TableCell>
              <TableCell>{t("FileTable:fileTableFileExtension")}</TableCell>
              <TableCell>{t("FileTable:fileTableFilesize")}</TableCell>
              <TableCell>{t("FileTable:fileTableDateCreated")}</TableCell>
              <TableCell>{t("FileTable:fileTableDateModified")}</TableCell>
              <TableCell>{t("FileTable:fileTableDownloadPrompt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{files}</TableBody>
        </Table>
      </TableContainer>
      {props.error ? (
        <Card>
          <Typography color="error" textAlign="center">
            {errorMessage}
          </Typography>
        </Card>
      ) : null}
    </div>
  );
}
