import { Languages } from "Types/Locale";

export const defaultEntries = {
  darkMode: true,
  language: Languages.AmericanEnglish
};

export interface LocalStorageEntry {
  key?: keyof typeof defaultEntries;
  type?: "Clear" | "Delete" | "Get" | "Set";
  value?: string;
}
