import React from "react";
import { Card, Typography, useTheme } from "@mui/material";

export enum StatusType {
  default,
  info,
  success,
  warning,
  error
}

export interface IStatusBannerProps {
  title: string,
  message: string,
  type: StatusType
}

export default function StatusBanner({ title, message, type }: IStatusBannerProps) {
  const theme = useTheme();
  let color = "";

  switch (type) {
    case StatusType.success:
      color = theme.palette.success.main;
      break;
    case StatusType.warning:
      color = theme.palette.warning.main;
      break;
    case StatusType.error:
      color = theme.palette.error.main;
      break;
    case StatusType.info:
    case StatusType.default:
    default:
      color = theme.palette.background.paper;
      break;
  }

  return (
    <Card className="StatusBannerContainer" sx={{ background: color }}>
      <Typography className="StatusBannerTitle" id="statustitle">{title}</Typography>
      <Typography className="StatusBannerMessage" id="statusmsg">{message}</Typography>
    </Card>
  );
}
