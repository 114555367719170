// Globals
import { Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";
import type { AffiliateProject } from "Types/Misc";

// Variables
import { AFFILIATE_NOVASTUDIOS_URL } from "vars";

interface AboutPageProps extends Page {}

function AboutPage(props: AboutPageProps) {
  // @ts-ignore
  const t = useTranslation(["About", "Common"]).t;

  useEffect(() => {
    props.changeTitleCallback(t("About:pageTitle"));
  }, [props, props.changeTitleCallback, t]);

  return (
    <PageContainer split>
      <div className="AboutLeft">
        <div>
          <Typography variant="h4">{t("About:pageTitle")}</Typography>
          <Typography variant="subtitle1">
            {/* @ts-ignore */}
            {t("Common:copyright", { year: new Date().getFullYear() })}
          </Typography>
        </div>
        <div>
          <Typography variant="h5">{t("About:acknowledgementsTitle")}</Typography>
          <ul className="ProjectsList">
          {(
            // @ts-ignore
            t("About:acknowledgementsContent", {
              returnObjects: true,
            }) as Array<string>
          ).map((translation: string, index) => {
            return (
              <li key={`project_${index}`}>
                <Typography variant="body1">{translation}</Typography>
              </li>
            );
          })}
        </ul>
        </div>
      </div>
      <div className="AboutRight">
        <Typography variant="body1">
          {t("About:affiliateNovaStudiosAbout")}
        </Typography>
        <ul className="ProjectsList">
          {(
            // @ts-ignore
            t("About:affiliateNovaStudiosProjects", {
              returnObjects: true,
            }) as Array<AffiliateProject>
          ).map((project: AffiliateProject, index) => {
            return (
              <li key={`project_${index}`}>
                <Typography variant="body1"><Link href={project.url}>{project.name}</Link></Typography>
              </li>
            );
          })}
        </ul>
        <Typography variant="body1">
          <Trans t={t} i18nKey="About:affiliateNovaStudiosLink">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <Link href={AFFILIATE_NOVASTUDIOS_URL} />
          </Trans>
        </Typography>
      </div>
    </PageContainer>
  );
}

export default AboutPage;
