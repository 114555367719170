export function next(currentPath: string, nextPath: string) {
  if (currentPath.endsWith("/")) return currentPath.concat(nextPath);
  return currentPath.concat("/", nextPath);
}

export function back(currentPath: string) {
  if (currentPath.endsWith("/")) currentPath = currentPath.substring(0, currentPath.length - 1);
  if (currentPath.length < 1) return root();
  return currentPath.substring(0, currentPath.lastIndexOf("/"));
}

export function root(variant?: "models") {
  switch (variant) {
    case "models":
      return "/models";
    default:
      return "/";
  }
}
