// Globals
import { CardMedia, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Lib
import MimeTypeParser, { FileType } from "Lib/MimeTypeParser";
import { back, next, root } from "Lib/FileTraverser";

// Components
import FileTable from "Components/Tables/FileTable/FileTable";
import FileViewer from "Components/Modals/FileViewer/FileViewer";
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";
import type { FileProperties } from "Types/Files";

// Variables
import { CDN_URL, MAIN_URL } from "vars";

// Resources
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

interface FileExplorerPageProps extends Page {}

function FileExplorerPage({ changeTitleCallback }: FileExplorerPageProps) {
  // @ts-ignore
  const t = useTranslation(["FileExplorer"]).t;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [fileList, updateFileList] = useState([] as FileProperties[]);
  const [path, setPath] = useState(root());
  const [selectedFile, setSelectedFile] = useState(
    null as unknown as FileProperties
  );
  const [fileViewerOpen, setViewerVisibility] = useState(false);
  const [isLoading, setViewerLoadingState] = useState(false);
  const [viewerErrorState, setViewerErrorState] = useState(false);
  const [fileTableErrorState, setFileTableErrorState] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const FetchFiles = () => {
    fetch(`${MAIN_URL}/php/QueryFiles.php?dir=${path}`).then((response) => {
      if (response.ok) {
        response.json().then((files: FileProperties[]) => {
          const processedFiles: FileProperties[] = [];

          for (const file of files) {
            const mimeType: FileType = new MimeTypeParser(file.basename).getGeneralizedFileType();
            const canBeOpened = mimeType === FileType.Audio || mimeType === FileType.Document || mimeType === FileType.Image || mimeType === FileType.Video;
            const url = CDN_URL.concat("/public", file.path!);

            if (file.extra) {
              file.extra.mimeType = mimeType;
              file.extra.canBeOpened = canBeOpened;
              file.extra.url = url;
            } else {
              file.extra = { canBeOpened, mimeType, url };
            }

            if (file.isDir) {
              // @ts-ignore
              file.extension = t("FileTableEntry:fileTableEntryTypeFolder");
            }

            processedFiles.push(file);
          }

          updateFileList(processedFiles);
          setFileTableErrorState(false);
        }).catch(() => {
          setPath(back(path));
          setFileTableErrorState(true);
        });
      }
      else {
        setPath(back(path));
        setFileTableErrorState(true);
      }
    }).catch(() => {
      setPath(back(path));
      setFileTableErrorState(true);
    });
  };

  useEffect(() => {
    changeTitleCallback(t("FileExplorer:pageTitle"));
  }, [changeTitleCallback, t]);

  useEffect(() => {
    FetchFiles();

    if (selectedFile != null) {
      setViewerErrorState(false);
      setViewerVisibility(true);
      setViewerLoadingState(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, path]);

  const viewerTitle = () => {
    if (selectedFile != null) {
      return selectedFile.filename;
    }
    return "";
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value != null && event.target.value !== searchQuery)
      setSearchQuery(event.target.value);
  };

  const onSearchClear = () => {
    if (searchQuery.length > 0) setSearchQuery("");
  };

  const onBack = () => {
    setPath(back(path));
  };

  const onRoot = () => {
    setPath(root());
  };

  const onFileOpen = (file: FileProperties) => {
    if (file.isDir) {
      setPath(next(path, file.filename));
    } else if (file.extra != null && file.extra.canBeOpened) {
      setSelectedFile(file);
    }
  };

  const viewerElementType = () => {
    if (
      selectedFile != null &&
      selectedFile.extra != null &&
      selectedFile.extra.url != null &&
      selectedFile.extra.mimeType != null &&
      selectedFile.extra.mimeType !== FileType.Unknown
    ) {
      switch (selectedFile.extra.mimeType) {
        case FileType.Image:
          return (
            <div className="FileExplorerViewerContentImage">
              <CardMedia
                className="FileExplorerViewerContent"
                component="img"
                src={selectedFile.extra.url}
                onLoad={() => setViewerLoadingState(false)}
                onError={() => setViewerErrorState(true)}
              />
            </div>
          );

        case FileType.Audio:
          return (
            <div className="FileExplorerViewerContentAudio">
              <CardMedia
                className="FileExplorerViewerContent"
                component="audio"
                controls
                src={selectedFile.extra.url}
                onCanPlayThrough={() => setViewerLoadingState(false)}
                onError={() => setViewerErrorState(true)}
              />
            </div>
          );

        case FileType.Video:
          return (
            <div className="FileExplorerViewerContentVideo">
              <CardMedia
                className="FileExplorerViewerContent"
                component="video"
                controls
                src={selectedFile.extra.url}
                onCanPlayThrough={() => setViewerLoadingState(false)}
                onError={() => setViewerErrorState(true)}
              />
            </div>
          );
        case FileType.Document:
          return (
            <div className="FileExplorerViewerContent FileExplorerViewerContentDocument">
              <Viewer
                fileUrl={selectedFile.extra.url}
                plugins={[defaultLayoutPluginInstance]}
                onDocumentLoad={() => setViewerLoadingState(false)}
              />
            </div>
          );
      }
    }

    return undefined;
  };

  return (
    <PageContainer>
      <div>
        <FileTable
          className="FileExplorerTable"
          title={t("FileExplorer:pageDescription", {path, interpolation: { escapeValue: false }})}
          fileList={fileList}
          error={fileTableErrorState}
          onSearchChange={onSearchChange}
          onSearchClear={onSearchClear}
          searchQuery={searchQuery}
          onReload={() => FetchFiles()}
          onFileOpen={onFileOpen}
          onBack={onBack}
          onRoot={onRoot}
        />
      </div>
      <FileViewer
        open={fileViewerOpen}
        title={viewerTitle()}
        isLoading={isLoading}
        onClose={() => {
          setViewerVisibility(false);
          setSelectedFile(null as unknown as FileProperties);
        }}
      >
        {viewerElementType()}
      </FileViewer>
      <Worker workerUrl="pdf.worker.min.js" />
    </PageContainer>
  );
}

export default FileExplorerPage;
