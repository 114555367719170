// Globals
import { useTranslation } from "react-i18next";
import { Card, Typography } from "@mui/material";
import View3D, { EVENTS } from "@egjs/view3d";
import { useEffect, useState } from "react";
import { CDN_URL, MAIN_URL } from "vars";

// Lib
import { back, next, root } from "Lib/FileTraverser";
import MimeTypeParser, { FileType } from "Lib/MimeTypeParser";

// Components
import FileViewer from "Components/Modals/FileViewer/FileViewer";
import FileTable from "Components/Tables/FileTable/FileTable";
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";
import type { FileProperties } from "Types/Files";

// Resources
import "@egjs/view3d/css/view3d-bundle.min.css";

interface ModelViewerPageProps extends Page {}

function ModelViewerPage(props: ModelViewerPageProps) {
  const t = useTranslation(["ModelViewer"]).t;

  const [fileList, updateFileList] = useState([] as FileProperties[]);
  const [path, setPath] = useState(root("models"));
  const [selectedObject, setSelectedObject] = useState(
    null as unknown as FileProperties
  );
  const [modelViewerVisible, setModelViewerVisibility] = useState(false);
  const [isLoading, setViewerLoadingState] = useState(false);
  const [modelViewerErrorState, setModelViewerErrorState] = useState(false);
  const [fileTableErrorState, setFileTableErrorState] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  function FetchModelList() {
    fetch(`${MAIN_URL}/php/QueryFiles.php?dir=${path}`)
      .then((response: Response) => {
        if (response.ok) {
          response
            .json()
            .then((files: FileProperties[]) => {
              const processedFiles: FileProperties[] = [];
              const parser: MimeTypeParser = new MimeTypeParser();

              for (const file of files) {
                parser.mimeTypeRaw = file.basename;
                const mimeType: FileType = parser.getGeneralizedFileType();
                const canBeOpened = mimeType === FileType.Model;
                const url = CDN_URL.concat("/public", file.path!);

                if (file.extra) {
                  file.extra.mimeType = mimeType;
                  file.extra.canBeOpened = canBeOpened;
                  file.extra.url = url;
                } else {
                  file.extra = { canBeOpened, mimeType, url };
                }

                if (file.isDir) {
                  // @ts-ignore
                  file.extension = t("FileTableEntry:fileTableEntryTypeFolder");
                }

                processedFiles.push(file);
              }

              updateFileList(processedFiles);
              setFileTableErrorState(false);
            })
            .catch((e) => {
              console.error(`Invalid JSON received: (${e})`);
              setFileTableErrorState(true);
            });

          return;
        }

        setFileTableErrorState(true);
      })
      .catch((e) => {
        console.error(e);
        setFileTableErrorState(true);
      });
  }

  useEffect(() => {
    props.changeTitleCallback(t("ModelViewer:pageTitle"));
  }, [props, t]);

  useEffect(() => {
    FetchModelList();

    let ModelViewer: View3D = null as unknown as View3D;

    try {
      if (selectedObject != null) {
        ModelViewer = new View3D(".ModelViewerWrapper", {
          src: selectedObject.extra?.url,
          envmap: `${CDN_URL}/public/models/hdrmap.hdr`,
          shadow: false,
          translate: false,
        });

        setViewerLoadingState(true);

        ModelViewer.on(EVENTS.READY, () => {
          setViewerLoadingState(false);
          console.log(`Loaded model ${selectedObject.filename}`);
        });
      }
    } catch (e) {
      console.error(`View3D ran into an error: ${e}`);
      setModelViewerErrorState(true);
    }

    return () => {
      if (ModelViewer != null) ModelViewer.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObject, path]);

  const noScroll = modelViewerVisible ? "NoScroll" : "";

  const viewerTitle = () => {
    if (selectedObject != null) {
      return selectedObject.filename;
    }
    return "";
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value != null && event.target.value !== searchQuery)
      setSearchQuery(event.target.value);
  };

  const onSearchClear = () => {
    if (searchQuery.length > 0) setSearchQuery("");
  };

  const onBack = () => {
    setPath(back(path));
  };

  const onRoot = () => {
    setPath(root("models"));
  };

  const onFileOpen = (file: FileProperties) => {
    if (file.isDir) {
      setPath(next(path, file.filename));
    } else if (file.extra != null && file.extra.canBeOpened) {
      setSelectedObject(file);
      setModelViewerVisibility(true);
    }
  };

  return (
    <PageContainer>
      <div className="ModelViewerDescriptionSection">
        <Typography variant="h4">{t("ModelViewer:pageTitle")}</Typography>
        <Typography variant="body1">
          {t("ModelViewer:pageDescription")}
        </Typography>
      </div>
      <FileTable
        onReload={() => FetchModelList()}
        fileList={fileList}
        error={fileTableErrorState}
        onSearchChange={onSearchChange}
        onSearchClear={onSearchClear}
        searchQuery={searchQuery}
        onFileOpen={onFileOpen}
        onBack={onBack}
        onRoot={onRoot}
      />
      <FileViewer
        open={modelViewerVisible}
        title={viewerTitle()}
        isLoading={isLoading}
        onClose={() => setModelViewerVisibility(false)}
        keepMounted
      >
        <div className="ModelViewerWrapper view3d-wrapper">
          <canvas className="ModelViewerCanvas view3d-canvas" />
          <Typography variant="caption" sx={{ marginTop: "auto", zIndex: 10 }}>
            {t("ModelViewer:modelViewerHint")}
          </Typography>
        </div>
      </FileViewer>
    </PageContainer>
  );
}

export default ModelViewerPage;
