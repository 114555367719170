// Globals
import { Button, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Components
import PageContainer from "Components/Containers/PageContainer/PageContainer";
import Section from "Components/Containers/Section/Section";

// Types
import type { Page } from "Types/Components";

interface HomePageProps extends Page {

}

function HomePage({ changeTitleCallback }: HomePageProps) {
  // @ts-ignore
  const t = useTranslation(["Home"]).t;

  useEffect(() => {
    changeTitleCallback(t("Home:pageTitle"));
  }, [changeTitleCallback, t]);

  const theme = useTheme();

  const styles = {
    homepageLogo: {
      filter: theme.palette.mode === "dark" ? "invert(1)" : "",
    }
  };

  return (
  <PageContainer className="HomePageContainer">
    <Section style={{ alignItems: "center", textAlign: "center" }}>
      <img style={styles.homepageLogo} src="logo.svg" width="256" height="256" alt="Yep Development Studios Logo"></img>
      <Typography variant="h4">{t("Home:greeting")}</Typography>
    </Section>
    <Section title={t("Home:linksHeader")} style={{ alignItems: "center" }}>
      <Button href="/fileexplorer" variant="text">{t("Home:buttonPublicResources")}</Button>
    </Section>
  </PageContainer>
  );
}

export default HomePage;
