import React from "react";
import { ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";

interface IHybridListItem {
  disabled?: boolean,
  icon?: JSX.Element,
  text?: string,
  href?: string,
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export default class HybridListItem extends React.Component<IHybridListItem> {
  icon?: JSX.Element;
  text: string;
  href: string;

  constructor(props: IHybridListItem) {
    super(props);
    this.icon = props.icon || undefined;
    this.text = props.text || "";
    this.href = props.href || "";
  }

  render() {
    return(
      <ListItemButton component={Link} disabled={this.props.disabled} to={this.href} onClick={(event: React.MouseEvent<HTMLAnchorElement>) => { if (this.props.onClick != null) this.props.onClick(event) }}>
        <ListItemIcon sx={{color: "text.primary"}}>{this.icon}</ListItemIcon>
        <ListItemText>{this.text}</ListItemText>
      </ListItemButton>
    );
  }
}
