// Globals
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Card, IconButton, Typography } from "@mui/material";
import { Home as HomeIcon, KeyboardBackspace as BackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

// Components
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";

interface ErrorPageProps extends Page {
  errorType: number
}

function ErrorPage(props: ErrorPageProps) {
  const t = useTranslation(["Error"]).t;

  useEffect(() => {
    props.changeTitleCallback(`${t("Error:pageTitle")} ${props.errorType}`);
  }, [props, props.changeTitleCallback, props.errorType, t]);

  const errorMessage = () => {
    switch (props.errorType) {
      case 403:
        return t("Error:description403");
      case 404:
      default:
        return t("Error:description404");
    }
  }

  return (
    <PageContainer style={{ textAlign: "center" }}>
      <Typography variant="h1">{props.errorType}</Typography>
      <Typography variant="h3">{errorMessage()}</Typography>
      <div className="ReferenceButtonsContainer">
        <Card>
          <IconButton onClick={() => window.history.back()}>
            <BackIcon />
          </IconButton>
          <IconButton component={Link} to="/home">
            <HomeIcon />
          </IconButton>
        </Card>
      </div>
    </PageContainer>
  );
}

export default ErrorPage;
