// Globals
import { Button, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Components
import PageContainer from "Components/Containers/PageContainer/PageContainer";

// Types
import type { Page } from "Types/Components";

interface ToolsPageProps extends Page {

}

function ToolsPage(props: ToolsPageProps) {
  const t = useTranslation(["Tools"]).t;

  useEffect(() => {
    props.changeTitleCallback(t("Tools:pageTitle"));
  }, [props, t]);

  return (
    <PageContainer>
      <div className="ToolContainer">
        <Typography variant="h5">{t("Tools:weightedMeanCalculatorContainerTitle")}</Typography>
        <div className="WeightedMeanCalculatorContainer">
          <TextField />
          <Button>{t("Tools:weightedMeanCalculatorCalculateButton")}</Button>
        </div>
      </div>
    </PageContainer>
  );
}

export default ToolsPage;
