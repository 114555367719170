import { createTheme } from "@mui/material";

export const LightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#E0E0E0",
      paper: "#FFFFFF"
    },
    primary: {
      main: "#3F51B5"
    }
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    }
  }
});

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#202324",
      paper: "#1a1c1d"
    },
    text: {
      primary: "#FFFFFF",
    },
    primary: {
      main: "#3F51B5",
    }
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    }
  }
});
